import Login from "@/components/auth/login";
import Register from "@/components/auth/register";
import ChristmasBalls from "@/components/christmas/christmas-balls";
import ChristmasCane from "@/components/christmas/christmas-cane";
import ChristmasHat from "@/components/christmas/christmas-hat";
import ChristmasRibbon from "@/components/christmas/christmas-ribbon";
import CommonNavbarSearch from "@/components/common-navbar-search";
import LogoutButton from "@/components/logout-button";
import { Dialog, DialogContent } from "@/components/ui/dialog";
import { $productSearchTerm } from "@/store/product-filtering";
import type { Shop, User } from "@/types";
import { Menu, Search, X } from "lucide-react";
import { useState, type PropsWithChildren } from "react";

interface Props extends PropsWithChildren {
  shop: Shop;
  user?: User | null;
}

const Header = ({ shop, user, children }: Props) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isSearchOpen, setIsSearchOpen] = useState(false);
  const [shouldLogin, setShouldLogin] = useState(false);
  const [shouldRegister, setShouldRegister] = useState(false);

  const handleSearch = (term: string) => {
    if (!term) {
      return;
    }
    // check if we're on the products page first
    if (window.location.pathname !== `/shops/${shop.slug}/products`) {
      window.location.href = `/shops/${shop.slug}/products?search=${term}`;
    } else {
      // update store
      $productSearchTerm.set(term);
    }
  };

  return (
    <header className="bg-kp-white sticky top-0 z-50">
      <div className="relative overflow-hidden">
        {/* xmas deco */}
        <div className="hidden md:block absolute -top-2 left-0 right-0 max-w-full overflow-hidden">
          <div className="flex items-center gap-1">
            {new Array(2000).fill(0).map((_, index) => (
              <ChristmasBalls
                className={`h-${(index % 4) + 3} w-auto`}
                key={index}
              />
            ))}
          </div>
        </div>

        {/* nav */}
        <div className="container mx-auto px-2">
          {/* Mobile Header */}
          <div className="lg:hidden flex items-center justify-between py-3">
            <button
              onClick={() => setIsMenuOpen(!isMenuOpen)}
              className="p-2 hover:bg-kp-accent/10 rounded-full flex-shrink-0"
              aria-label="Toggle menu">
              {isMenuOpen ? (
                <X className="w-6 h-6 text-kp-black" />
              ) : (
                <Menu className="w-6 h-6 text-kp-black" />
              )}
            </button>

            <a
              href={`/shops/${shop?.slug}`}
              className="relative flex items-center flex-grow justify-center">
              <div className="absolute -top-2 left-0">
                <ChristmasHat className="w-auto h-8" />
              </div>
              {shop.logo ? (
                <div>
                  <img
                    src={shop.logo}
                    alt={shop.name}
                    className="h-8 w-auto mr-2 flex-shrink-0"
                  />
                  <div className="hidden text-xs mt-1 font-normal text-kp-black leading-tight">
                    {shop?.slogan}
                  </div>
                </div>
              ) : (
                <div className="relative">
                  <div className="text-center relative">
                    <div className="text-kp-black text-sm font-bold leading-tight">
                      {shop?.name}
                    </div>
                    <div className="text-xs font-normal text-kp-secondary leading-tight">
                      {shop?.slogan}
                    </div>
                  </div>
                  <div className="absolute top-0 -left-4">
                    <ChristmasCane className="h-8 w-auto -rotate-[38deg] opacity-85" />
                  </div>
                  <div className="absolute top-0 -right-4">
                    <ChristmasCane className="h-8 w-auto rotate-[35deg] transform -scale-x-100 opacity-85" />
                  </div>
                </div>
              )}
            </a>

            <div className="flex items-center gap-2 flex-shrink-0">
              <button
                onClick={() => setIsSearchOpen(!isSearchOpen)}
                className="p-2 hover:bg-kp-accent/10 rounded-full"
                aria-label="Toggle search">
                <Search className="w-6 h-6 text-kp-black" />
              </button>

              {/* cart + favourite button */}
              {children}
            </div>
          </div>

          {/* Mobile Search */}
          {isSearchOpen && (
            <div className="lg:hidden py-3 px-2 border-t border-primary/20">
              <CommonNavbarSearch
                placeholder={`Search products in ${shop.name}`}
                className="w-full px-4 py-2 pl-10 border border-primary/20 rounded-full focus:outline-none focus:ring-2 focus:ring-primary transition-all bg-white/50"
                onChange={handleSearch}
              />
            </div>
          )}

          {/* Mobile Menu */}
          {isMenuOpen && (
            <div className="lg:hidden border-t border-kp-black/10">
              <nav className="py-4 space-y-4">
                <div className="flex flex-col gap-2">
                  {user ? (
                    <>
                      <a href="/dashboard">
                        <button className="w-full px-4 py-2 text-sm font-medium border rounded transition-colors text-center hover:bg-kp-black hover:text-kp-primary">
                          My Account
                        </button>
                      </a>

                      <LogoutButton className="bg-kp-black text-white hover:bg-kp-primary" />
                    </>
                  ) : (
                    <>
                      <button
                        onClick={() => setShouldLogin(true)}
                        className="w-full px-4 py-2 text-sm font-medium border rounded transition-colors text-center hover:bg-kp-black hover:text-kp-primary">
                        Login
                      </button>

                      <button
                        onClick={() => setShouldRegister(true)}
                        className="w-full px-4 py-2 bg-kp-black hover:text-kp-primary text-kp-white rounded text-sm font-medium transition-colors">
                        Sign Up
                      </button>
                    </>
                  )}
                </div>
              </nav>
            </div>
          )}

          {/* Desktop Header */}
          <div className="hidden lg:flex items-center justify-between py-4 gap-8">
            <a
              href={`/shops/${shop?.slug}`}
              className="text-2xl font-bold shrink-0 flex items-center relative">
              <div className="flex items-baseline">
                <img
                  src={shop.logo || ""}
                  alt={shop.name}
                  className="h-[80px] w-auto mr-2"
                />
                <div className="hidden text-sm mt-1 font-normal text-kp-black">
                  {shop?.slogan}
                </div>
              </div>
              <div className="absolute -top-2 -left-1">
                <ChristmasHat className="w-auto h-10" />
              </div>
              <div className="relative hidden">
                <div className="text-kp-black hidden">{shop?.name}</div>
                <div className="text-sm font-normal text-kp-black">
                  {shop?.slogan}
                </div>

                <div className=" absolute bottom-0 -right-8">
                  <ChristmasCane className="h-10 w-auto" />
                </div>
                <div className="absolute -bottom-4 right-0 left-0">
                  <ChristmasRibbon className="h-5 w-auto mx-auto opacity-90" />
                </div>
              </div>
            </a>

            <div className="flex-1 max-w-3xl">
              <CommonNavbarSearch
                placeholder={`Search products in ${shop.name}`}
                className="w-full pl-10 border border-primary/10 rounded-lg focus:outline-none focus:ring-2 focus:ring-primary focus:border-0 focus-visible:ring-primary transition-all"
                onChange={handleSearch}
              />
            </div>

            <div className="flex items-center gap-4">
              {/* cart + favourite button */}
              {children}

              {user ? (
                <>
                  <a href="/dashboard">
                    <button className="px-4 py-2 text-sm font-medium hover:bg-primary/10 rounded transition-colors">
                      My Account
                    </button>
                  </a>

                  <LogoutButton className="bg-black text-primary-foreground hover:bg-accent" />
                </>
              ) : (
                <>
                  <button
                    onClick={() => setShouldLogin(true)}
                    className="px-4 py-2 text-sm font-medium hover:bg-kp-accent/10 rounded transition-colors">
                    Login
                  </button>

                  <button
                    onClick={() => setShouldRegister(true)}
                    className="px-4 py-2 bg-kp-black text-kp-white rounded text-sm font-medium hover:bg-kp-primary hover:text-white transition-colors">
                    Sign Up
                  </button>
                </>
              )}
            </div>
          </div>
        </div>
      </div>

      {/* login modal */}
      <Dialog open={shouldLogin} onOpenChange={setShouldLogin}>
        <DialogContent className="sm:max-w-[425px]">
          <Login
            siteName={shop.name}
            buttonClasses="bg-kp-black hover:bg-kp-black hover:opacity-90">
            <div className="mx-auto flex gap-1 text-sm">
              <p>Don&apos;t have an account yet?</p>
              <button
                type="button"
                className="underline"
                onClick={() => {
                  setShouldLogin(false);
                  setShouldRegister(true);
                }}>
                Sign Up
              </button>
            </div>
          </Login>
        </DialogContent>
      </Dialog>

      {/* register modal */}
      <Dialog open={shouldRegister} onOpenChange={setShouldRegister}>
        <DialogContent className="sm:max-w-[425px]">
          <Register
            siteName={shop.name}
            enableShopRegistration={false}
            buttonClasses="bg-kp-black hover:bg-kp-black hover:opacity-90">
            <div className="mx-auto flex gap-1 text-sm">
              <p>Already have an account?</p>
              <button
                type="button"
                className="underline"
                onClick={() => {
                  setShouldRegister(false);
                  setShouldLogin(true);
                }}>
                Login
              </button>
            </div>
          </Register>
        </DialogContent>
      </Dialog>
    </header>
  );
};

export default Header;
